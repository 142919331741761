<template>
  <div>
    <CContainer
      style="max-width: 400px; background: #d9dbdf"
      class="mt-5 p-0 rounded-lg"
    >
      <CRow class="px-4 pt-3">
        <CCol md="12">
          <h2>Mernis Kontrol</h2>
        </CCol>
      </CRow>
      <hr />
      <CRow class="px-4">
        <CCol md="12">
          <CInput type="text" label="Ad" v-model="form.firstname" />
        </CCol>
      </CRow>
      <CRow class="px-4">
        <CCol md="12">
          <CInput type="text" label="Soyad" v-model="form.lastname" />
        </CCol>
      </CRow>
      <CRow class="px-4">
        <CCol md="4">
          <CInput
            type="number"
            label="Doğum Yılı"
            v-mask="'####'"
            v-model.number="form.birthYear"
          />
        </CCol>

        <CCol md="8">
          <CInput
            type="number"
            min="11"
            max="11"
            v-mask="'###########'"
            label="TC Kimlik Numarası"
            v-model.number="form.tcNo"
          />
        </CCol>
      </CRow>
      <hr />
      <CRow class="px-4 pb-3">
        <CCol md="12">
          <CButton color="primary" size="lg" @click="control"
            >Kontrol Et</CButton
          >
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import { IMaskComponent } from "vue-imask";

Vue.use(VueSweetalert2);
export default {
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        birthYear: "",
        tcNo: "",
      },
    };
  },
  methods: {
    async control() {
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/tc-verify",
          this.form,
          { withCredentials: true }
        );
        this.$store.dispatch({
          type: "successSwal",
          text: "Girilen bilgiler uyumludur",
          refresh: false,
        });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.errors[0].message,
        });
      }
    },
  },
};
</script>
