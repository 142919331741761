var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CContainer",
        {
          staticClass: "mt-5 p-0 rounded-lg",
          staticStyle: { "max-width": "400px", background: "#d9dbdf" },
        },
        [
          _c(
            "CRow",
            { staticClass: "px-4 pt-3" },
            [
              _c("CCol", { attrs: { md: "12" } }, [
                _c("h2", [_vm._v("Mernis Kontrol")]),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "CRow",
            { staticClass: "px-4" },
            [
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c("CInput", {
                    attrs: { type: "text", label: "Ad" },
                    model: {
                      value: _vm.form.firstname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "firstname", $$v)
                      },
                      expression: "form.firstname",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CRow",
            { staticClass: "px-4" },
            [
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c("CInput", {
                    attrs: { type: "text", label: "Soyad" },
                    model: {
                      value: _vm.form.lastname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lastname", $$v)
                      },
                      expression: "form.lastname",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CRow",
            { staticClass: "px-4" },
            [
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c("CInput", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "####",
                        expression: "'####'",
                      },
                    ],
                    attrs: { type: "number", label: "Doğum Yılı" },
                    model: {
                      value: _vm.form.birthYear,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "birthYear", _vm._n($$v))
                      },
                      expression: "form.birthYear",
                    },
                  }),
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("CInput", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "###########",
                        expression: "'###########'",
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: "11",
                      max: "11",
                      label: "TC Kimlik Numarası",
                    },
                    model: {
                      value: _vm.form.tcNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tcNo", _vm._n($$v))
                      },
                      expression: "form.tcNo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c(
            "CRow",
            { staticClass: "px-4 pb-3" },
            [
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "primary", size: "lg" },
                      on: { click: _vm.control },
                    },
                    [_vm._v("Kontrol Et")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }